// モジュールとなるjsファイルの読み込み
// analytics
import {module_analytics} from './js/googleAnalytics';

// vendors
//import {module_jquery_easing} from './js/jquery.easing';
import {module_slick} from './js/slick.min';
import {module_magnific} from './js/jquery.magnific-popup.min';
import {module_magnificPopup} from './js/magnificPopup';
import {module_simpleTicker} from './js/jquery.simpleTicker';
//import {module_photoswipe} from './js/photoswipe';
//import {module_photoswipeUiDefault} from './js/photoswipe-ui-default';
//import {module_photoswipeInit} from './js/photoswipe_Init';
import {module_rollover2} from './js/rollover2';
import {module_tab} from './js/tab';
//import {module_example} from './js/example';
import {module_scrolltopcontrol} from './js/scrolltopcontrol';

// pages
import {module_common} from './js/common';
import {module_index} from './js/index';
import {module_blog} from './js/blog';


// モジュール先にある関数を実行
module_analytics();

//module_jquery_easing();
module_slick();
module_magnific();
module_magnificPopup();
module_simpleTicker();
//module_photoswipe();
//module_photoswipeUiDefault();
//module_photoswipeInit();
module_rollover2();
module_tab();
//module_example();
module_scrolltopcontrol();

module_common();
module_index();
module_blog();

// Sassファイルの読み込み
//import './sass/style.scss';