export function module_blog() {
$(function() {
		//--------------------------------------------------------
		//アーカイブ開閉
		//--------------------------------------------------------
		$('.blogArchiveList-month').hide();
		$('.archiveYear span').click(function(){
			$(this).next("ul").slideToggle();
			$(this).parent("li").toggleClass("open");
		});
	});
}