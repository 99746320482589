export function module_magnificPopup() {
	$(function () {
		//--------------------------------------------------------
		//Magnific Popup Setteing
		//--------------------------------------------------------
		$('.gallery, .button, .wp-block-gallery').each(function() {
			$(this).magnificPopup({
				delegate: 'a',
				type: 'image',
				mainClass: 'mfp-fade',
				removalDelay: 100,
				gallery:{enabled:true}
			});
		});
	});
}
