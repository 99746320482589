export function module_common() {
	$(function() {
		
		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		commonResize();
		
		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on("resize", function() {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function() {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if ( winWidth != winWidth_resized || winHeight != winHeight_resized ) {
					commonResize();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//共通リサイズイベント
		function commonResize() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;
			
			var result = $('body').find('.contetWrapper');
			if (!result.length) {
				$('body').wrapInner('<div class="contetWrapper">');
			}
			
			//ヘッダーメニューの#リンクをクリック無効に
			$('.btnSubMenu').children('.btnDisabled').click(function(event){
				event.preventDefault();
			});
			
			//windowサイズでの挙動
			if($(window).width() > 768) {
				
				// PC ////////////////////////////////////
				$('.gNavMenu').css("display","flex");
				$('.gNavMenu').insertAfter('.btnSpMenu');
				$('.gNavMenuBox').removeClass('openMenu');
				// サブメニュー展開
				$('.btnSubMenu').hover(function(){
					$(this).find('.gNavListSub').stop().slideDown();
				}, function(){
					$(this).find('.gNavListSub').stop().slideUp();
					return false;
				});
				//ヘッダー髙さを取得してコンテンツ部分上部に余白を設定
				var hHeight = $('header').height();
				$('.contents').css("marginTop", hHeight);
				
			} else {
				
				// SP ////////////////////////////////////
				$('.gNavMenu').css("display","none");
				$('.gNavMenu').insertBefore('header');
				$('.contents').css("marginTop", 0);
				
				//Mobile Menu
				var state = false;
				var scrollpos;

				$('.btnSpMenu, .btnCloseMenu, .spMenuOverflow, a[href*="#"]:not(.pagetop a):not(#tab a)').on('click', function(event){
					//event.preventDefault();

					if(state == false) {
						// 背景コンテンツ固定
						scrollpos = $(window).scrollTop();
						$('body').addClass('fixed').css({'top': -scrollpos});
						// グローバルメニュー表示
						$('.gNavMenu').fadeIn(200);
						$('.gNavMenuBox').addClass('openMenu');
						state = true;
					} else {
						// 背景コンテンツ固定 解除
						$('body').removeClass('fixed').css({'top': 0});
						window.scrollTo( 0 , scrollpos );
						// グローバルメニュー非表示
						$('.gNavMenu').fadeOut(200);
						$('.gNavMenuBox').removeClass('openMenu');
						state = false;
					}
				});
			}
		} //commonResize
		
		
		//--------------------------------------------------------
		// スマホ時電話番号にリンク
		//--------------------------------------------------------
		var ua = navigator.userAgent;
		if(ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1 ){
			$('.telLink').each(function(){
				var str = $(this).text();
				$(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
			});
		}
	});
}
